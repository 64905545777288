var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"1600px"},model:{value:(_vm.dgoOnlineDilog),callback:function ($$v) {_vm.dgoOnlineDilog=$$v},expression:"dgoOnlineDilog"}},[_c('v-card',{attrs:{"min-height":"465"}},[_c('v-card-title',{staticClass:"px-2 pb-5",staticStyle:{"border-bottom":"1px solid #ccc"}},[_c('v-app-bar',{attrs:{"height":"50","flat":"","color":"rgba(0, 0, 0, 0)"}},[_c('v-toolbar-title',{staticClass:"text-h5 pl-0 pt-0"},[_vm._v(" Relatório DGO ")]),_c('v-spacer'),_c('v-btn',{staticClass:"mt-7",attrs:{"color":"red","fab":"","icon":"","dark":"","x-small":"","absolute":"","top":"","right":""},on:{"click":_vm.toogleDgoOnlineDilog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('h3',{staticClass:"blue-grey--text text--darken-3 mt-5 ml-7"},[_c('div',{staticClass:"font-weight-medium"},[_vm._v(" Período de referência: "),_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.periodDates))])])]),_c('v-card-text',{staticClass:"mt-10"},[_c('v-data-table',{staticClass:"elevation-0 dgoReport",attrs:{"headers":_vm.headers,"items":_vm.subscribers,"single-expand":"","expanded":_vm.expanded,"item-key":"id","show-expand":"","no-data-text":"Selecione Data inicial, Data final e clique em pesquisar."},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
return [_c('v-btn',{attrs:{"color":"primary","outlined":"","rounded":"","x-small":""},on:{"click":function($event){return _vm.handleExpansion(item, isExpanded)}}},[_vm._v(" "+_vm._s(isExpanded ? "- Detalhes" : "+ Detalhes")+" ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"px-2 py-4",attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"px-0 py-0",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('h3',{staticClass:"px-8 pt-4 pb-3"},[_vm._v("Detalhamento da subscrição")])]),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-data-table',{staticClass:"elevation-0 px-5",attrs:{"hide-default-footer":"","headers":_vm.packagesHeader,"items":item.addPackage,"item-key":"id"},scopedSlots:_vm._u([{key:"item.package",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"px-0 py-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","md":"2"}},[_c('v-avatar',{attrs:{"size":"36px","color":"black"}},[_c('v-img',{attrs:{"contain":"","height":"25px","src":_vm.setImagePack(item.package)}})],1)],1),_c('v-col',{staticClass:"d-flex align-center pl-2",attrs:{"cols":"10","md":"7"}},[_vm._v(" "+_vm._s(item.package)+" ")])],1)]}},{key:"item.subscription_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.subscription_date))+" ")]}},{key:"item.discountRule",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"max-width":"240","disabled":item.discountRule == null,"bottom":"","color":"success"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"grey--text text--darken-3"},'a',attrs,false),on),[_vm._v(" "+_vm._s(item.discountRule ? item.discountRule : "Sem descontos")),(item.discountRule)?_c('v-icon',{staticClass:"mb-1 ml-1",attrs:{"small":"","color":"primary"}},[_vm._v("info")]):_vm._e()],1)]}}],null,true)},[(item.promotionInformation)?_c('span',{domProps:{"innerHTML":_vm._s(item.promotionInformation)}}):_vm._e()])]}},{key:"item.amountPay",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"disabled":item.due_date_discount == null,"bottom":"","color":"success","content-class":"text-center"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"grey--text text--darken-3"},'a',attrs,false),on),[_vm._v(" "+_vm._s(item.discountValue ? ("R$ " + (_vm.$options.filters.formatPrice( item.discountValue ))) : ("R$ " + (_vm.$options.filters.formatPrice( item.totalValue ))))),(item.due_date_discount)?_c('v-icon',{staticClass:"mb-1 ml-1",attrs:{"small":"","color":"primary"}},[_vm._v("info")]):_vm._e()],1)]}}],null,true)},[(item.discountValue)?_c('span',{domProps:{"innerHTML":_vm._s(("Valor válido até " + (item.due_date_discount
                                                ? _vm.$options.filters.formatDate(
                                                    item.due_date_discount
                                                )
                                                : ''))
                                                )}}):_vm._e()])]}},{key:"item.totalValue",fn:function(ref){
                                                var item = ref.item;
return [_c('div',{staticClass:"pl-2"},[_vm._v("R$ "+_vm._s(_vm._f("formatPrice")(item.totalValue)))])]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',{staticClass:"sumLine"},[_c('td',{staticClass:"extraLightBlue"}),_c('td',{staticClass:"extraLightBlue"}),_c('td',{staticClass:"extraLightBlue"}),_c('td',{staticClass:"extraLightBlue text-right pr-0"},[_vm._v("Total")]),_c('td',{staticClass:"extraLightBlue"},[_vm._v(" R$ "),_c('b',[_vm._v(_vm._s(_vm._f("formatPrice")(item.total)))])])])])],2)],1)],1)],1)]}},{key:"item.name",fn:function(ref){
                                                var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"caption"},[_vm._v(_vm._s(item.email))])]}},{key:"item.expirationDay",fn:function(ref){
                                                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.expirationDay))+" ")]}},{key:"item.subscription_date",fn:function(ref){
                                                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.subscription_date))+" ")]}},{key:"item.document",fn:function(ref){
                                                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCnpjCpf")(item.document))+" ")]}},{key:"item.total",fn:function(ref){
                                                var item = ref.item;
return [_vm._v(" R$ "+_vm._s(_vm._f("formatPrice")(item.total))+" ")]}}])})],1),_c('v-card-actions',{staticClass:"px-0 mx-3",staticStyle:{"border-top":"1px solid rgb(220, 220, 220)"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.toogleDgoOnlineDilog}},[_vm._v(" Fechar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }