<template>
    <v-dialog v-model="statusBilletDilog" persistent scrollable width="960px">
        <v-card min-height="465">
            <v-card-title style="border-bottom: 1px solid #ccc;" class="px-2"><v-app-bar height="50" flat
                    color="rgba(0, 0, 0, 0)">
                    <v-toolbar-title class="text-h5 pl-0 pt-0">
                        {{ titleStaus }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn @click="toogleStatusBilletDilog" class="mt-6" color="red" fab icon dark x-small absolute top
                        right>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-app-bar>
            </v-card-title>
            <v-card-subtitle class="mb-2 pt-10 pb-0" v-html="periodStatus"></v-card-subtitle>
            <v-card-text>

                <v-data-table :items-per-page="5" :headers="headers" :items="billetsByStatus" class="elevation-1">
                    <template v-slot:item="{ item }">
                        <tr>
                            <td class="pt-1">
                                <div :class="[item.days_late > 0 ? 'red--text text--lighten-1' : 'primary--text']">
                                    <b>
                                        {{ item.due_date | formatDate }}
                                    </b>
                                </div>
                                <small> Dias em atraso: <b
                                        :class="[item.days_late > 0 ? 'red--text text--lighten-1' : 'primary--text']">{{
                                            item.days_late }}</b></small>
                            </td>
                            <td> {{ item.name_drawee }} </td>
                            <td class="text-center"> {{ item.issue_date | formatDate }} </td>
                            <td class="text-right"> R$ {{ item.value | formatPrice }} </td>
                        </tr>
                    </template>
                </v-data-table>

            </v-card-text>
            <v-card-actions class="px-0 mx-3" style="border-top: 1px solid rgb(220, 220, 220)">
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="toogleStatusBilletDilog">
                    Fechar
                </v-btn>
                <!-- <v-btn color="green" text @click="saveProfile"> Salvar </v-btn> -->
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import MyFilters from "../../../../filters/genericFilters";
import * as moment from "moment";
export default {
    data() {
        return {
            headers: [
                // days_late: 0
                // document_drawee: "2"
                // due_date: "2023-03-10"
                // issue_date: "2023-02-24"
                // name_drawee: ""
                // notification_date: null
                // number_title: null
                // payment_date: "2023-02-27"
                // value: "650.50"
                { text: "Vencimento", value: "due_date", sortable: false, align: "left" },
                { text: "Sacado", value: "name_drawee", sortable: false },
                { text: "Data de emissão", value: "issue_date", sortable: false, align: "center" },
                { text: "Valor do boleto", value: "value", sortable: false, align: "right" },
            ]
        }
    },
    props: {
        billetsByStatus: Array,
        toogleStatusBilletDilog: Function,
        statusBilletDilog: Boolean,
        titleStaus: String,
        periodStatus: String
    },
}
</script>

<style></style>