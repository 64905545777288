<template>
    <v-card :class="['mt-5', pagamentoWidth > 55 ? '' : 'ml-8']" :width="`${pagamentoWidth}%`">
        <v-row justify="start" class="pr-5">
            <v-col cols="12" md="6" class="mt-1">
                <v-card-title>
                    Boletos
                </v-card-title>
                <v-card-subtitle class="text-left mb-1">
                    Status dos boletos por período
                </v-card-subtitle>
            </v-col>
            <v-col cols="12" md="6" class="pt-9 pb-0 d-flex">
                <v-menu ref="calendar" v-model="calendar" :close-on-content-click="false" transition="scale-transition"
                    max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field readonly color="primary" outlined dense
                            :append-icon="dates.length > 1 ? '' : 'date_range'" @click:append="calendar = !calendar"
                            clearable class="rangeInputDate subtitle-2" v-model="dateRangeText"
                            label="Selecione o período" hint="Data inicial e data final." persistent-hint v-bind="attrs"
                            v-on="on"></v-text-field>
                    </template>
                    <v-date-picker class="rangeMode" range v-model="dates" no-title>
                        <div style="width: 100%" class="bordTop d-flex flex-row justify-start">
                            <v-btn class="mt-2" text color="primary" @click="getBilletsByPeriod">
                                OK
                            </v-btn>
                            <v-btn class="mt-2" text color="primary" @click="calendar = false">
                                Cancelar
                            </v-btn>
                        </div>
                    </v-date-picker>
                </v-menu>
            </v-col>
        </v-row>
        <v-divider style="border: solid 1px;" class="blue-grey darken-1"></v-divider>
        <v-card-text class="">
            <v-row justify="start">
                <v-col cols="12" class="">
                    <v-alert v-if="!objByPeriod.opened" color="greyprimary" outlined type="warning" prominent
                        border="left" class="text-left">
                        Selecione no campo acima um período válido com data inicial e data final para listar o relatório
                        dos
                        seus
                        boletos.
                    </v-alert>
                    <v-card v-if="objByPeriod.opened" elevation="0" class="px-2">
                        <v-card-title class="pt-0 pl-0 red--text text--darken-3">
                            <v-icon color="red darken-3" class="mr-2">update</v-icon> Boletos em aberto
                        </v-card-title>
                        <v-card-subtitle class="mb-2 pt-1 text-left pl-1">
                            Período de <b>{{ objByPeriod.opened.start_date | formatDate
                                }} a {{ objByPeriod.opened.end_date | formatDate }}</b>
                        </v-card-subtitle>
                        <v-card-text class="pb-0 px-0">
                            <v-row no-gutters>
                                <!-- md="2" -->
                                <v-col cols="12" class="text-left">
                                    <span class=" text-left">Porcentagem de boletos em aberto {{ percents('') }}%</span>
                                    <v-progress-linear background-color="red lighten-3" color="red darken-3"
                                        :value="percents('')"></v-progress-linear>
                                </v-col>
                                <!-- <v-spacer></v-spacer> -->
                                <!-- <v-col cols="2" style="margin-top: -22px;" class="d-flex justify-center"> -->
                                <div
                                    style="top: 0; right: 5px; text-align: center; max-width: 50px; position: absolute;">
                                    <v-avatar color="red darken-3" size="42">
                                        <span class="white--text text-h6">{{ objByPeriod.opened.quantity
                                            }}</span>
                                    </v-avatar>
                                </div>
                                <!-- </v-col> -->
                            </v-row>

                        </v-card-text>
                        <!-- <v-divider></v-divider> -->
                        <v-card-actions class="px-0">
                            <v-chip class="ma-2" color="red lighten-5" text-color="red darken-3">
                                <b class="mr-2">Total</b> R$ {{ objByPeriod.opened.summarized_value |
                                    formatPrice }}
                            </v-chip>
                            <v-spacer></v-spacer>
                            <v-btn text color="red darken-3"
                                @click="getBilletsByStatus(objByPeriod.opened.start_date, objByPeriod.opened.end_date, 'opened')">
                                + Mais detalhes
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                    <v-divider v-if="objByPeriod.opened" class="mt-3 mb-6"></v-divider>
                    <v-card v-if="objByPeriod.liquidated" elevation="0" class="px-2">
                        <v-card-title class="pt-0 pl-0 green--text text--darken-3">
                            <v-icon color="green darken-3" class="mr-2">verified</v-icon> Boletos liquidados
                        </v-card-title>
                        <v-card-subtitle class="mb-2 pt-1 text-left px-1">
                            Pagos no período de <b>{{ objByPeriod.liquidated.start_date | formatDate
                                }} a {{ objByPeriod.liquidated.end_date | formatDate }}</b>
                        </v-card-subtitle>
                        <v-card-text class="pb-0 px-0">
                            <v-row no-gutters>
                                <!-- md="2" -->
                                <v-col cols="12" class="text-left">
                                    <span>Porcentagem de boletos liquidados {{ percents('liq') }}%</span>
                                    <v-progress-linear background-color="green lighten-3" color="green darken-3"
                                        :value="percents('liq')"></v-progress-linear>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="2" style="margin-top: -22px;" class="d-flex justify-center">
                                    <div
                                        style="top: 0; right: 5px; position: absolute; text-align: center; max-width: 50px;">
                                        <v-avatar color="green darken-3" size="42">
                                            <span class="white--text text-h6">{{ objByPeriod.liquidated.quantity
                                                }}</span>
                                        </v-avatar>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <!-- <v-divider></v-divider> -->
                        <v-card-actions class="px-0">
                            <v-chip class="ma-2" color="green lighten-5" text-color="green darken-3">
                                <b class="mr-2">Total</b> R$ {{ objByPeriod.liquidated.summarized_value |
                                    formatPrice }}
                            </v-chip>
                            <v-spacer></v-spacer>
                            <v-btn text color="green darken-3"
                                @click="getBilletsByStatus(objByPeriod.liquidated.start_date, objByPeriod.liquidated.end_date, 'liquidated')">
                                + Mais detalhes
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <StatusBilletsDialog :periodStatus="periodStatus" :billetsByStatus="billetsByStatus"
                :titleStaus="titleStaus" :toogleStatusBilletDilog="toogleStatusBilletDilog"
                :statusBilletDilog="statusBilletDilog" />
        </v-card-text>
    </v-card>
</template>

<script>
import StatusBilletsDialog from "./StatusBilletsDialog.vue";
export default {
    watch: {
    },
    methods: {
        toogleStatusBilletDilog() {
            this.statusBilletDilog = !this.statusBilletDilog
        },
        getBilletsByStatus(init, end, status) {
            let query = {
                "start_date": init,
                "end_date": end
            }
            if (status == 'liquidated') {
                this.titleStaus = "Listagem de boletos liquidados"
                this.periodStatus = `Boletos pagos no período de <b> ${this.$options.filters.formatDate(init)} a ${this.$options.filters.formatDate(end)}</b>`
                query.status = "Liquidado"
            } else {
                this.titleStaus = "Listagem de boletos em aberto"
                this.periodStatus = `Boletos em aberto no período de <b> ${this.$options.filters.formatDate(init)} a ${this.$options.filters.formatDate(end)}</b>`
                //this.periodStatus = ``
                query.status = "Aberto"
            }
            var obj = {
                url: "/api/v1/user/report/getByStatus",
                query: query,
                method: "post",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                //console.log(response.data)
                this.billetsByStatus = [...response.data]
                this.toogleStatusBilletDilog()
            })
        },
        verifyDueDAte(d1) {
            let date = d1.split('-')
            let expired = false
            var a = moment(d1);
            var b = moment();
            if (a.diff(b, 'days') < 0) {
                expired = true
            }
            return expired
        },
        percents(type) {
            let percent = ""
            let qtdLiq = this.objByPeriod.liquidated ? this.objByPeriod.liquidated.quantity : 0;
            let qtdopened = this.objByPeriod.opened ? this.objByPeriod.opened.quantity : 0;

            let total = qtdLiq + qtdopened

            if (type == 'liq') {
                percent = (qtdLiq * 100) / total
            } else {
                percent = (qtdopened * 100) / total
            }
            // console.log('ssss',parseInt(percent))
            return parseInt(percent)
        },
        getBilletsByPeriod(init, end) {
            this.calendar = false

            if (this.dates.length <= 1) {
                console.log('dates', this.dates)
                return
            }
            var obj = {
                url: "/api/v1/user/report/getByPeriod",
                query: {
                    "start_date": this.dates[0],
                    "end_date": this.dates[1]
                },
                method: "post",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                // console.log('ereref')
                if (!response.data.liquidated && !response.data.opened) {
                    this.objByPeriod = {}
                    return
                }
                this.objByPeriod = { ...response.data }
                // this.getBilletsSummarized(init, end);
            })
        },
        getBilletsSummarized(init, end) {
            var obj = {
                url: "/api/v1/user/report/getSummarizedTitles",
                query: {
                    "start_date": init,
                    "end_date": end
                },
                method: "post",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                this.billets = { ...response.data }
            })
        },
        formatRangeDate(listDates) {
            let returnDate = "";
            for (let i = 0; i < listDates.length; i++) {
                let [year, month, day] = listDates[i].split("-");
                returnDate += `${day}/${month}/${year}${i == 0 ? " - " : ""}`;
            }
            // console.log(returnDate);
            this.periodDates = returnDate
            return returnDate;
        },
    },
    data() {
        return {
            dates: [],
            calendar: false,
            periodStatus: "",
            billetsByStatus: [],
            titleStaus: "",
            statusBilletDilog: false,
            headers: [
                // "title_number":
                // "due_date":
                // "payment_date":
                // "value":

                { text: "Status", value: "status", sortable: false },
                { text: "Boleto", value: "title_number", sortable: false },
                // { text: "Pagamento", value: "payment_date", sortable: false },
                // { text: "Vencimento", value: "due_date", sortable: false },
                { text: "Valor", value: "value", sortable: false },
            ],
            objByPeriod: {},
            billets: {}
        }
    },
    mounted() {
        // this.getBilletsByPeriod("2022-01-04", "2024-04-15");
    },
    computed: {
        dateRangeText: {
            // getter
            get: function () {
                return this.formatRangeDate(this.dates);
            },
            // setter
            set: function (newValue) {
                if (!newValue) {
                    this.dates = [];
                }
            },
        },
    },
    props: {
        pagamentoWidth: Number
    },
    components: {
        StatusBilletsDialog,
    },
}
</script>

<style></style>
