<template>
    <v-dialog persistent v-model="dgoOnlineDilog" scrollable max-width="1600px">
        <v-card min-height="465">
            <v-card-title style="border-bottom: 1px solid #ccc;" class="px-2 pb-5"><v-app-bar height="50" flat
                    color="rgba(0, 0, 0, 0)">
                    <v-toolbar-title class="text-h5 pl-0 pt-0">
                        Relatório DGO
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn @click="toogleDgoOnlineDilog" class="mt-7" color="red" fab icon dark x-small absolute top
                        right>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-app-bar>
            </v-card-title>
            <h3 class="blue-grey--text text--darken-3 mt-5 ml-7">
                <div class="font-weight-medium">
                    Período de referência: <span class="font-weight-medium">{{ periodDates }}</span>
                </div>
            </h3>
            <v-card-text class="mt-10">
                <v-data-table :headers="headers" :items="subscribers" single-expand :expanded.sync="expanded"
                    item-key="id" show-expand class="elevation-0 dgoReport"
                    no-data-text="Selecione Data inicial, Data final e clique em pesquisar.">
                    <template v-slot:item.data-table-expand="{ item, isExpanded }">
                        <v-btn color="primary" @click="handleExpansion(item, isExpanded)" outlined rounded x-small>
                            {{ isExpanded ? "- Detalhes" : "+ Detalhes" }}
                        </v-btn>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                        <td class="px-2 py-4" :colspan="headers.length">
                            <v-row no-gutters class="px-0 py-0">
                                <v-col cols="12" md="12" class="">
                                    <h3 class="px-8 pt-4 pb-3">Detalhamento da subscrição</h3>
                                </v-col>
                                <v-col cols="12" md="12" class="">
                                    <!-- hide-default-header -->
                                    <v-data-table hide-default-footer :headers="packagesHeader" :items="item.addPackage"
                                        item-key="id" class="elevation-0 px-5">
                                        <template v-slot:item.package="{ item }">
                                            <v-row no-gutters class="px-0 py-0">
                                                <!-- v-if="item.service.prefix == 'Disney+'" -->
                                                <v-col cols="12" md="2" class="d-flex align-center">
                                                    <v-avatar size="36px" color="black" class="">
                                                        <v-img class="" contain height="25px"
                                                            :src="setImagePack(item.package)"></v-img>
                                                    </v-avatar>
                                                </v-col>
                                                <v-col cols="10" md="7" class="d-flex align-center pl-2">
                                                    {{ item.package }}
                                                </v-col>
                                            </v-row>
                                        </template>
                                        <template v-slot:item.subscription_date="{ item }">
                                            {{ item.subscription_date | formatDate }}
                                        </template>

                                        <template v-slot:item.discountRule="{ item }">
                                            <v-tooltip max-width="240" :disabled="item.discountRule == null" bottom
                                                color="success">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <a v-bind="attrs" v-on="on" class="grey--text text--darken-3">
                                                        {{
                                                            item.discountRule
                                                                ? item.discountRule
                                                                : "Sem descontos"
                                                        }}<v-icon v-if="item.discountRule" small color="primary"
                                                            class="mb-1 ml-1">info</v-icon></a>
                                                    <!-- <div v-show="item.templates == ''">Não há templates</div> -->
                                                </template>
                                                <span v-if="item.promotionInformation" class=""
                                                    v-html="item.promotionInformation"></span>
                                            </v-tooltip>
                                        </template>
                                        <template v-slot:item.amountPay="{ item }">
                                            <v-tooltip :disabled="item.due_date_discount == null" bottom color="success"
                                                content-class="text-center">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <a v-bind="attrs" v-on="on" class="grey--text text--darken-3">
                                                        {{
                                                            item.discountValue
                                                                ? `R$ ${$options.filters.formatPrice(
                                                                    item.discountValue
                                                                )}`
                                                                : `R$ ${$options.filters.formatPrice(
                                                                    item.totalValue
                                                                )}`
                                                        }}<v-icon v-if="item.due_date_discount" small color="primary"
                                                            class="mb-1 ml-1">info</v-icon></a>
                                                </template>
                                                <span v-if="item.discountValue" class="" v-html="`Valor válido até ${item.due_date_discount
                                                    ? $options.filters.formatDate(
                                                        item.due_date_discount
                                                    )
                                                    : ''
                                                    }`
                                                    "></span>
                                            </v-tooltip>
                                        </template>
                                        <template v-slot:item.totalValue="{ item }">
                                            <div class="pl-2">R$ {{ item.totalValue | formatPrice }}</div>
                                        </template>

                                        <template slot="body.append">
                                            <tr class="sumLine">
                                                <td class="extraLightBlue"></td>
                                                <td class="extraLightBlue"></td>
                                                <td class="extraLightBlue"></td>
                                                <td class="extraLightBlue text-right pr-0">Total</td>
                                                <td class="extraLightBlue">
                                                    R$ <b>{{ item.total | formatPrice }}</b>
                                                </td>
                                            </tr>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </td>
                    </template>
                    <template v-slot:item.name="{ item }">
                        <div>
                            {{ item.name }}
                        </div>
                        <div class="caption">{{ item.email }}</div>
                    </template>

                    <template v-slot:item.expirationDay="{ item }">
                        {{ item.expirationDay | formatDate }}
                    </template>
                    <template v-slot:item.subscription_date="{ item }">
                        {{ item.subscription_date | formatDate }}
                    </template>
                    <template v-slot:item.document="{ item }">
                        {{ item.document | formatCnpjCpf }}
                    </template>
                    <template v-slot:item.total="{ item }">
                        R$ {{ item.total | formatPrice }}
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions class="px-0 mx-3" style="border-top: 1px solid rgb(220, 220, 220)">
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="toogleDgoOnlineDilog">
                    Fechar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    methods: {
        handleExpansion(item, state) {
            // console.log(item, state);
            this.expanded = [];
            const itemIndex = this.expanded.indexOf(item);
            state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
        },
        setImagePack(pack) {
            //let img;
            switch (pack) {
                case "Pacote Básico Mensal":
                    return this.dtvGo;
                    break;
                case "Telecine":
                    return this.telecine;
                    break;
                case "HBO MAX":
                    return this.hbo;
                    break;
                case "Comembol":
                    return this.comembol;
                    break;
                case "Combate":
                    return this.combate;
                    break;
                case "ESPN extra":
                    return this.espn;
                    break;
                case "Brasileirão":
                    return this.brasilerao;
                    break;

                default:
                //console.log(`Sorry, we are out of.`);
            }
        },
    },
    data() {
        return {
            dtvGo: require("@/assets/pacotes-extras/directvgo.png"),
            telecine: require("@/assets/pacotes-extras/telecine.png"),
            comembol: require("@/assets/pacotes-extras/Conmebol_BRAZIL_001.png"),
            hbo: require("@/assets/pacotes-extras/hbo.png"),
            combate: require("@/assets/pacotes-extras/combate.png"),
            espn: require("@/assets/pacotes-extras/espn_extra.png"),
            brasilerao: require("@/assets/pacotes-extras/prem.png"),

            expanded: [],
            singleExpand: false,
            packagesHeader: [
                {
                    text: "Pacotes extras",
                    value: "package",
                    align: "start",
                    sortable: false,
                },
                {
                    text: "Data da subscrição",
                    value: "subscription_date",
                    align: "start",
                    sortable: false,
                },
                {
                    text: "Desconto",
                    value: "discountRule",
                    align: "start",
                    sortable: false,
                },
                {
                    text: "Plano sem desconto",
                    value: "totalValue",
                    align: "start",
                    sortable: false,
                },
                {
                    text: "Valor a pagar",
                    value: "amountPay",
                    align: "start",
                    sortable: false,
                },
            ],
            headers: [
                {
                    text: "Assinante",
                    value: "name",
                    align: "start",
                    sortable: true,
                },
                {
                    text: "CPF",
                    value: "document",
                    align: "left",
                    sortable: false,
                },
                {
                    text: "Data da subscrição",
                    value: "subscription_date",
                    sortable: false,
                    align: "center",
                },
                {
                    text: "Próximo vencimento",
                    value: "expirationDay",
                    sortable: false,
                    align: "center",
                },
                {
                    text: "Valor total",
                    value: "total",
                    align: "start",
                    sortable: false,
                },
                // Pacotes extras
                { text: "", value: "data-table-expand", align: "center" },
            ],
        };
    },
    props: {
        subscribers: Array,
        toogleDgoOnlineDilog: Function,
        dgoOnlineDilog: Boolean,
        periodDates: String
    },
}
</script>

<style>
.dgoReport tr.v-data-table__expanded__content{
    box-shadow: inset 2px 2px 5px 0px rgba(0,0,0,0.35) !important;
}
</style>