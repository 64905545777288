<template>
    <div v-if="myBilletData ? true : false"
        style="position: absolute; right: 0; top: 5px; min-width: 420px; min-height: 180px; border-left: 1px solid #ccc;"
        class="d-flex pl-8 mt-3 pb-3 pt-2">
        <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
                <v-btn @click="" v-bind="attrs" v-on="on" color="primary"
                    style="top: 75px; box-shadow: 2px 3px 5px -2px rgba(0, 0, 0, 0.5);" fab small absolute dark top
                    right class="v-btn--example mr-1">
                    <v-icon>file_download</v-icon>
                </v-btn>
            </template>
            <span>Download em PDF</span>
        </v-tooltip>
        <div class="text-left mr-1" style="position: absolute; right: 0;">
            <span style="display: block; line-height: 3px;" class="caption mr-2">Valor</span>
            <span style="font-size: 24px !important;" class="title success--text">R$ {{ myBilletData.value | formatPrice
                }}</span>
        </div>
        <div style="width: 100%;" class="d-flex flex-column flex-wrap">
            <div class="d-flex align-center justify-start mb-3">
                <h6 style="font-size: 18px; color: #6c6c6c;" class="text-left font-weight-medium">
                    Meu boleto</h6>
            </div>
            <v-row class="mt-0">
                <v-col cols="12" class="mt-0 text-left">
                    <v-avatar class="mr-3" color="green lighten-5" rounded size="28"><v-icon color="success"
                            size="20">event_available</v-icon></v-avatar>
                    <span class="text-subtitle-2 success--text">Referência: {{ myBilletData.closing_date |
                        formatDate }}</span>
                </v-col>
            </v-row>
            <v-row class="mt-0">
                <v-col cols="12" class="mt-0 text-left">
                    <v-avatar class="mr-3" color="error lighten-5" rounded size="28"><v-icon color="error"
                            size="20">event</v-icon></v-avatar>
                    <span class="text-subtitle-2 error--text">Vencimento: {{ myBilletData.due_date |
                        formatDate }}</span>
                </v-col>
            </v-row>
            <v-row class="mt-2">
                <v-col cols="12" class="mt-0 text-left">
                    <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="copyDigLine(myBilletData.whiteable_line)" v-bind="attrs" v-on="on" fab
                                x-small color="primary" class="mr-2">
                                <v-icon small>content_copy</v-icon>
                            </v-btn>
                        </template>
                        <span>Copiar linha digitável</span>
                    </v-tooltip>
                    <span class="font-weight-medium pt-1 body-2">{{ myBilletData.whiteable_line }}</span>
                    <v-expand-transition>
                        <v-alert v-if="reveal" dense outlined type="success"
                            class="mt-3 pr-10 transition-fast-in-fast-out v-alert--reveal">
                            Linha digitavel copiada!
                        </v-alert>
                    </v-expand-transition>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import MyFilters from "../../../../filters/genericFilters";
export default {
    methods: {
        copyDigLine(text) {
            if (!navigator.clipboard) {
                this.fallbackCopyTextToClipboard(text);
                return;
            }
            let _this = this;
            navigator.clipboard.writeText(text).then(
                function () {
                    _this.reveal = true
                    setTimeout(() => {
                        _this.reveal = false
                    }, 2500);
                },
                function (err) {
                    console.error("Async: Could not copy text: ", err);
                }
            );
        },
        getMyBillet() {
            var obj = {
                url: "/api/v1/user/invoice/notification",
                query: {},
                method: "post",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                if (!response) {
                    return
                }
                if (Array.isArray(response.data)) {
                    this.myBilletData = null
                    return
                }
                this.myBilletData = { ...response.data }
            })
        }
    },
    data() {
        return {
            myBilletData: null,
            reveal: false,
        }
    },
    mounted() {
        if (this.$store.state.clientPermissions.newAreapermissions.pagamento.hasProd) {
            this.getMyBillet();
        }
    },
}
</script>

<style lang="scss" scoped></style>