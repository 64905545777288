<template>
    <v-container class="fill-height px-12">
        <v-responsive class="align-start text-center fill-height pa-1">
            <MyBillet />
            <v-row class="mt-1 mb-2 d-flex align-start justify-space-between">
                <v-col cols="12" class="mb-10">
                    <h4 class="titlePages text-left font-weight-medium mb-0">
                        Relatórios administrativos
                    </h4>
                    <p style="max-width: 40%;" class="text-body-2 font-weight-light text-left mt-4">
                        Bem-vindo à área de relatórios financeiros! Aqui você terá acesso a informações detalhadas e
                        atualizadas sobre seus produtos. Através de relatórios precisos e
                        personalizáveis.
                    </p>
                </v-col>
                <v-col cols="12" class="d-flex justify-start">
                    <v-card v-if="showDtvgo" class="mt-5" :width="`${dtvgoWidth}%`">

                        <v-row justify="start" class="pr-5">
                            <v-col cols="12" md="6" class="mt-1 text-left">
                                <v-card-title class="mb-2 mt-2">
                                    <v-img max-width="180" contain class="" :src="dtvGo"></v-img>
                                </v-card-title>
                                <v-card-subtitle class="text-left">
                                    Relatório de subscrições DGO
                                </v-card-subtitle>
                            </v-col>
                            <v-col cols="12" md="6" class="pr-3 pt-9 pb-0">
                                <v-menu ref="calendar" v-model="calendar" :close-on-content-click="false"
                                    transition="scale-transition" max-width="290px" min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field readonly color="primary" outlined dense
                                            :append-icon="dates.length > 1 ? '' : 'date_range'"
                                            @click:append="calendar = !calendar" clearable
                                            class="rangeInputDate subtitle-2" v-model="dateRangeText"
                                            label="Selecione o período" hint="Data inicial e data final."
                                            persistent-hint v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker class="rangeMode" range v-model="dates" no-title>
                                        <div style="width: 100%" class="bordTop d-flex flex-row justify-start">
                                            <v-btn class="mt-2" text color="primary" @click="calendar = false">
                                                OK
                                            </v-btn>
                                            <v-btn class="mt-2" text color="primary" @click="calendar = false">
                                                Cancelar
                                            </v-btn>
                                        </div>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-card-text class="" style="border-top: 2px solid #5a77b3;">
                            <v-col cols="12" class="px-0">
                                <v-card-title class="my-0 pa-0">
                                    Relatório por período
                                </v-card-title>
                                <v-card-subtitle class="text-left pa-0 my-5 ml-1 px-5">
                                    Selecione no campo acima uma data inicial e uma data final para geração do
                                    relatório.<br>Os períodos
                                    podem ser definidos conforme a sua necessidade, tornando possível relatórios
                                    mensais, trimestrais,
                                    semestrais etc...
                                </v-card-subtitle>
                            </v-col>
                            <v-row class="px-5 mt-1 mb-2 d-flex align-start justify-space-between">
                                <v-col cols="12" class="px-5 pt-0">
                                    <v-btn @click="getSubscribersReport" :disabled="dates.length > 1 ? false : true"
                                        block color="primaryNew" dark class="mb-2 disabBt">
                                        Gerar relatório online <v-icon right> settings </v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="6" class="pl-5 pt-0">
                                    <!-- v-if="!abranetExcel" @click="generateExcel(false)" -->
                                    <v-btn @click="generateExcel(false)" :disabled="dates.length > 1 ? false : true"
                                        block small color="success" dark class="mt-5 disabBtPlalh">
                                        Gerar planilha<v-icon small right> assignment </v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="6" class="pr-5 pt-0">
                                    <!-- v-if="!abranetExcel" @click="generateExcel(true)" -->
                                    <v-btn @click="generateExcel(true)" :disabled="dates.length > 1 ? false : true"
                                        block small color="teal darken-2" dark class="disabBtExt mr-2 mt-5">
                                        Extrato DTVgo<v-icon small right> receipt_long </v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <BilletReportcard v-if="showPagamento" :pagamentoWidth="pagamentoWidth" />
                </v-col>
            </v-row>
        </v-responsive>
        <DgoOnline :subscribers="subscribers" :periodDates="periodDates" :toogleDgoOnlineDilog="toogleDgoOnlineDilog"
            :dgoOnlineDilog="dgoOnlineDilog" />
    </v-container>
</template>

<script>
import DgoOnline from './DgoOnline.vue'
import BilletReportcard from './BilletReportcard.vue'
import MyBillet from './MyBillet.vue'
export default {
    watch: {
        updatedPermissionsOk(v) {
            console.log('atual perm', v)
            if (v) {

                this.defineReports()
            }
        }
    },
    methods: {
        defineReports() {
            let reports = []
            if (this.$store.state.clientPermissions.newAreapermissions.dtvgo.hasProd) {
                reports.push('dtvgo')
                this.showDtvgo = true
                this.dtvgoWidth = 50
            }
            if (this.$store.state.clientPermissions.newAreapermissions.pagamento.hasProd) {
                reports.push('pagamento')
                this.showPagamento = true
                this.pagamentoWidth = 55
            }
            if (reports.length > 1) {
                return
            }
            if (reports[0] == 'dtvgo') {
                this.showPagamento = false
                this.dtvgoWidth = 100
            } else {
                this.showDtvgo = false
                this.pagamentoWidth = 100
            }
        },
        getSubscribersReport() {
            let query = {
                // service_id: 2,
                prefix: "dtvgo",
                partner_id: this.externalPartnerId ? this.externalPartnerId : localStorage.getItem("partner_id"),
                start_date: this.dates[0],
                end_date: this.dates[1],
            };
            var obj = {
                url: this.externalUrl ? this.externalUrl : "/api/v1/subscriptionAPI/partner/get-report",
                query: query,
                method: "post",
            };
            //console.log(obj);
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                //console.log(response.data.Total);
                if (response.data.reports.length) {
                    // this.showDetailsReport = true;
                    this.subscriberSum = response.data.Total
                        ? response.data.Total
                        : "0.00";
                }
                this.subscribers = [
                    ...response.data.reports.map((item) => {
                        item.addPackage.map((pack) => {
                            pack.tot = item.total;
                            return pack;
                        });
                        return item;
                    }),
                ];
                this.toogleDgoOnlineDilog()
                // console.log('0000',this.subscribers)
            });
        },
        toogleDgoOnlineDilog() {
            this.dgoOnlineDilog = !this.dgoOnlineDilog
        },
        generateExcel(extract) {
            //console.log(this.initialDateFormated, this.endDateFormated);
            // if (!this.initialDateFormated || !this.endDateFormated) {
            //     return;
            // }

            var obj = {
                url: "/api/v1/subscriptionAPI/partner/report/xls",
                query: {
                    // service_id: 2,
                    prefix: "dtvgo",
                    partner_id: this.externalPartnerId ? this.externalPartnerId : localStorage.getItem("partner_id"),
                    start_date: this.dates[0],
                    end_date: this.dates[1],
                    new_report: extract ? true : false
                },
                method: "post",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                //console.log(response);
                if (!response) {
                    return;
                }
                let obj = {};
                if (!response.data.link) {
                    obj = {
                        message: "Não existem dados para o período selecionado.",
                        title: "Período inválido!",
                        callback: (_) => { },
                        type: "warning",
                        btConfirm: { name: "Ok", show: true },
                        btCancel: { name: "Não", show: false },
                    };
                } else {
                    obj = {
                        message: "Planilha gerada com sucesso, deseja fazer o download do arquivo?",
                        title: "Sucesso!",
                        callback: (_) => {
                            window.open(response.data.link);
                        },
                        type: "success",
                        btConfirm: { name: "Sim", show: true },
                        btCancel: { name: "Não", show: true },
                    };
                }
                this.$store.commit("confirmMessage/showConfirmMessage", obj);
            });
        },
        formatRangeDate(listDates) {
            let returnDate = "";
            for (let i = 0; i < listDates.length; i++) {
                let [year, month, day] = listDates[i].split("-");
                returnDate += `${day}/${month}/${year}${i == 0 ? " - " : ""}`;
            }
            // console.log(returnDate);
            this.periodDates = returnDate
            return returnDate;
        },
    },
    data() {
        return {
            showDtvgo: false,
            showPagamento: false,
            dtvgoWidth: 50,
            pagamentoWidth: 55,
            reports: [],
            subscribers: [],
            periodDates: "",
            dgoOnlineDilog: false,
            dates: [],
            calendar: false,
            dtvGo: require("@/assets/directvgo.svg"),
            billetDialog: false,
            creditCardDialog: false
        }
    },
    mounted() {
        this.defineReports();
    },
    computed: {
        updatedPermissionsOk() {
            return this.$store.state.clientPermissions.newAreapermissions.updatedPermissionsOk
        },
        dateRangeText: {
            // getter
            get: function () {
                return this.formatRangeDate(this.dates);
            },
            // setter
            set: function (newValue) {
                if (!newValue) {
                    this.dates = [];
                }
            },
        },
    },
    components: {
        DgoOnline,
        MyBillet,
        BilletReportcard
    },
}
</script>

<style lang="scss" scoped>
.disabBt.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: #5a77b3 !important;
    opacity: .7;
}

.disabBtPlalh.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: #5ab55e !important;
    opacity: .7;
}

.disabBtExt.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: #148477 !important;
    opacity: .7;
}
</style>